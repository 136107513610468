import React from "react";
import urls from "../../../areas/main/assets/urls";
import Texts from "../../../utils/lang-utils";
import "./HowItWorks.scss";

const HowItWorks = () => {
  return (
    <section className="service-one" id="integrations" style={{ minHeight: 'auto' }}>
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title" dangerouslySetInnerHTML={{ __html: Texts.QROrdering.OtherServicesTitle }} />
          
        </div>
        <div className=" text-center ">
        <a href={urls.qrMenu} className="thm-btn">
                <span>{Texts.QROrdering.QRMenu}</span>
              </a>
        <a href={urls.apps} className="thm-btn">
        <span>{Texts.QROrdering.WaiterKitchenApps}</span>
        </a>
        <div style={{ marginTop: '3rem' }}>
          <small  dangerouslySetInnerHTML={{ __html: Texts.QROrdering.GartnerDisclaimer }} />
          </div>
    </div>


      </div>
    </section>
  );
};
export default HowItWorks;
